import {useEffect, useReducer} from 'react';
import {fetchAuth} from './Utils';

const cache = {};

export const useBusinesses = () => {
    const initialState = {businesses: []};

    const [state, dispatch] = useReducer((state, action) => {
        switch (action.type) {
            case 'FETCHED':
                return {...initialState, businesses: action.payload};
            default:
                return state;
        }
    }, initialState);

    useEffect(() => {
        const fetchData = async () => {
            if (cache.data) {
                const data = cache.data;
                dispatch({type: 'FETCHED', payload: data});

            } else {
                const result = await fetchAuth('/rest/businesses', {
                    method: 'GET',
                    retries: 5,
                    retryDelay: 2000,
                })
                    .then(sr => sr.json())
                    .then(bs => Object.entries(bs).map(obj => ({dn: obj[1], id: obj[0]})).sort((a, b) => (a.dn > b.dn) ? 1 : ((b.dn > a.dn) ? -1 : 0)));

                cache['data'] = result;
                dispatch({type: 'FETCHED', payload: result});
            }
        };

        fetchData().then();

    }, []);

    return state;
};
