import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import BlockOutlined from '@material-ui/icons/Close';
import {getFirebaseAuth} from '../Auth';

const AvatarUser = () => {
    const user = getFirebaseAuth().currentUser;

    if (!user)
        return <div/>;

    let letter = '?';
    const name = (user.displayName || '').trim();

    const signout = () => getFirebaseAuth().signOut().then(() => window.location = '/');

    if (name.length > 0) {
        const split = name.split(' ');
        letter = split[0].substr(0, 1).toUpperCase();

        if (split.length > 1)
            letter += split[split.length - 1].substr(0, 1).toUpperCase();
    }

    return <div style={{right: '40px', position: 'absolute'}}>
        <div style={{display: 'flex', alignItems: 'center'}}>
            <Avatar alt={name} src={user.photoURL || ''}>
                {letter}
            </Avatar>
            <IconButton color='primary' onClick={signout}><BlockOutlined/></IconButton>
        </div>
    </div>;
};
export default AvatarUser;
