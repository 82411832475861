import React, {useContext, useState} from 'react';
import {AppContext} from "../AppContext";
import {fetchAuth, RESTRICTION_LEVEL} from './Utils';
import LinearProgress from '@material-ui/core/LinearProgress';
import Paper from '@material-ui/core/Paper';
import ListItemText from '@material-ui/core/ListItemText';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Button from '@material-ui/core/Button';
import {Tooltip} from '@material-ui/core';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import {DateTime} from "luxon";
import ProjectSearch from './ProjectSearch';

const DATE_SINCE = {
    A_WEEK_AGO: DateTime.now().minus({weeks: 1}).toISODate(),
    A_MONTH_AGO: DateTime.now().minus({months: 1}).toISODate(),
    THREE_MONTHS_AGO: DateTime.now().minus({months: 3}).toISODate(),
    SIX_MONTHS_AGO: DateTime.now().minus({months: 6}).toISODate(),
    A_YEAR_AGO: DateTime.now().minus({months: 12}).toISODate()
};

const help = {
    'RESTRICTED': 'Default strategy. Personal/sensitive data from users will be unaccessible',
    'RESTRICTED_BUT_CONTACT_ID': 'Personal/sensitive data from users will be unaccessible, except for whatsapp phone number',
    'NO_RESTRICTION': 'Personal/sensitive data from users will be accessible. Try to avoid this strategy as much as possible'
};

const BigQueryMenu = () => {
    const appContext = useContext(AppContext);
    const {reportError} = appContext;

    const [serverStatus, setServerStatus] = useState([]);
    const [requesting, setRequesting] = useState(true);
    const [business, setBusiness] = useState(null);
    const [restriction, setRestriction] = useState(RESTRICTION_LEVEL.RESTRICTED);
    const [dateSince, setDateSince] = useState(DATE_SINCE.A_WEEK_AGO);


    const requestAccess = () => {
        setRequesting(true);

        fetchAuth(`/rest/grant-bq-person/${business.id}/${dateSince}/${restriction}`, {
            method: 'GET',
            retries: 0
        })
            .then(sr => sr.json())
            .then(json => setServerStatus(json.status))
            .then(() => setRequesting(false))
            .catch(reportError);
    };


    return <div className='root'>
        {requesting && <LinearProgress/>}

        <div>
            <span className='title'>Here botmakers request access to client's data. This request will be saved and audited based on our security policies.</span>
        </div>
        <div>
            <span className='title'>The allowance will automatically be deleted after 24 hours.</span>
        </div>

        <div className='section'>
            <ProjectSearch
                requesting={requesting}
                setRequesting={setRequesting}
                onBusiness={setBusiness}
            />

            <ButtonGroup className='formItem' size="small" color="primary">
                {Object.entries(RESTRICTION_LEVEL).map((itm, idx) => <Tooltip key={'br_' + idx} title={help[itm[0]] || ''}>
                    <Button disabled={requesting} onClick={() => setRestriction(itm[1])} variant={restriction === itm[1] ? 'outlined' : 'contained'}>
                        {itm[0].replaceAll('_', ' ')}
                    </Button>
                </Tooltip>)}
            </ButtonGroup>
        </div>

        <div className='section'>
            <ButtonGroup className='formItem' size="small" color="primary">
                {Object.entries(DATE_SINCE).map((itm, idx) => <Tooltip key={'bds_' + idx} title={help[itm[0]] || ''}>
                    <Button disabled={requesting} onClick={() => setDateSince(itm[1])} variant={dateSince === itm[1] ? 'outlined' : 'contained'}>
                        {itm[0].replaceAll('_', ' ')}
                    </Button>
                </Tooltip>)}
            </ButtonGroup>
        </div>

        <div className='section' style={{justifyContent: 'center'}}>
            <Button disabled={requesting || !business} onClick={requestAccess} variant='contained' color='primary' style={{minWidth: '50%'}}>{requesting ? 'Requesting' : 'Request access'}</Button>
        </div>

        {serverStatus.length > 0 && <Paper>
            <div>
                <p>
                    You can now proceed to <a href={'https://console.cloud.google.com/bigquery?project=m-infra'} target='_blank' rel='noopener noreferrer'>BigQuery console</a>
                </p>
            </div>

            <List>
                {serverStatus.length > 0 && serverStatus.map((sta, idx) => (<ListItem key={'t_' + idx}>
                    <ListItemText primary={sta}/>
                </ListItem>))}
            </List>
        </Paper>}
    </div>;
};
export default BigQueryMenu;
