import React, {useContext, useEffect, useState} from 'react';
import {AppContext} from "../AppContext";
import LinearProgress from "@material-ui/core/LinearProgress";
import {getFirebaseAuth, initialize} from '../Auth';
import {GoogleAuthProvider, signInWithRedirect} from "firebase/auth";

const Access = props => {
    const {setAccessed} = props;
    const appContext = useContext(AppContext);
    const {push, root, reportError} = appContext;
    const [authOk, setAuthOk] = useState(false);
    const [comesFromPreviousLogin, setComesFromPreviousLogin] = useState(false);

    useEffect(() => {
        initialize(user => {
            //props.change({ auth: { ...props.s.auth, userId: user.uid } })
            // if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development')
            //     console.log('user', user);

            if (user) {
                setComesFromPreviousLogin(true);
                // setUserEmail(user?.email);
                // setUserId(user?.uid);
            }

            setAuthOk(true);
            setAccessed(true);

        }, err => {
            reportError(err);
            setAuthOk(true);
        }, () => setAuthOk(true));

    }, [reportError, setAccessed]);

    useEffect(() => {
        if (comesFromPreviousLogin) {
            push("/menu");
        }
    }, [comesFromPreviousLogin, push]);

    const authError = (error, credential) => {
        reportError(new Error(JSON.stringify({
            errorCode: error.code,
            errorMessage: error.message,
            email: error.email,
            credential
        })));

        reportError(error);
    };

    const onGoogle = () => signInWithRedirect(getFirebaseAuth(), new GoogleAuthProvider())
        .catch(error => authError(error, GoogleAuthProvider.credentialFromError(error)));

    const gbutton = 'Login with Google';

    if (!authOk)
        return <LinearProgress/>;

    return <div>
        <div className='buttons-block'>
            <button className='gbutton' aria-label={gbutton} onClick={onGoogle}>
                <img height={20} width={20} alt={gbutton} src={`${root}gmail.svgz`}/>
                <span>{gbutton}</span>
            </button>
        </div>
    </div>;
};
export default React.memo(Access);
