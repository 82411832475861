import {initializeApp} from "firebase/app"
import {getAuth, onAuthStateChanged} from "firebase/auth";

const firebaseConfig = {
    apiKey: 'AIzaSyBnm3LkNL9G0FhILD_QaU9RqJqbvshXoJU',
    authDomain: 'access-management-320505.firebaseapp.com',
    databaseURL: 'https://access-management-320505.firebaseio.com'
};

let firebaseApp = null;

export function initialize(onUser, onError, onInited) {
    if (!firebaseApp) {
        firebaseApp = initializeApp(firebaseConfig, 'defaultAuth');

        const auth = getAuth(firebaseApp);
        auth.useDeviceLanguage();

        onAuthStateChanged(auth, user => onUser(user), err => onError(err));
    } else {
        onInited();
    }
}

export function getFirebaseAuth() {
    return getAuth(firebaseApp);
}
