import React, {useContext, useEffect, useState} from 'react';
import {fetchAuth, RESTRICTION_LEVEL} from './Utils';
import LinearProgress from '@material-ui/core/LinearProgress';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import {Tooltip, Typography} from '@material-ui/core';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ProjectSearch from './ProjectSearch';
import TextField from '@material-ui/core/TextField';
import {AppContext} from "../AppContext";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {DateTime} from "luxon";

const help = {
    'EXPIRES_IN_24_HS': 'Recommended for production, real customers',
    'NEVER_EXPIRES': 'Recommended for development or internal bots',
    'RESTRICTED': 'Default strategy. Personal/sensitive data from users will be unaccessible',
    'RESTRICTED_BUT_CONTACT_ID': 'Personal/sensitive data from users will be unaccessible, except for whatsapp phone number',
    'NO_RESTRICTION': 'Personal/sensitive data from users will be accessible. Try to avoid this strategy as much as possible'
};

const PlatformGrant = () => {
    const appContext = useContext(AppContext);
    const {reportError} = appContext;

    const [accessReady, setAccessReady] = useState(false);
    const [requesting, setRequesting] = useState(true);
    const [reason, setReason] = useState('');
    const [business, setBusiness] = useState(null);
    const [restriction, setRestriction] = useState(RESTRICTION_LEVEL.RESTRICTED);
    const [expire, setExpire] = useState('24h');
    const [grants, setGrants] = useState([]);

    const refresh = () => fetchAuth('/rest/grants', {
        method: 'GET',
        retries: 3,
        retryDelay: 2000,
    })
        .then(sr => sr.json())
        .then(obj => setGrants(obj.result))
        .then(() => setRequesting(false))
        .catch(reportError);

    useEffect(() => {
            refresh().then();
        },
        // eslint-disable-next-line
        []);

    const gotoAccess = () => {
        setAccessReady(false);
        window.open('https://go.botmaker.com', '_blank');

        resetState();
    };

    const requestAccess = () => {
        setRequesting(true);

        fetchAuth(`/rest/grant-bot-person/${business.id}/${expire}/${restriction}`, {
            method: 'GET',
            retries: 0
        })
            .then(sr => sr.json())
            .then(json => console.log(json.status))
            .then(() => {
                setRequesting(false);
                setAccessReady(true);
            })
            .catch(reportError);
    };

    const resetState = () => {
        setRequesting(true);

        refresh().then(() => {
            setReason('');
            setBusiness(null);
            setRestriction(RESTRICTION_LEVEL.RESTRICTED);
            setExpire('24h');
        });
    };

    const onDeleteGrant = (email, businessId, type) => {
        setRequesting(true);

        fetchAuth(`/rest/delete-grant/${email}/${businessId}/${type}`, {
            method: 'GET',
            retries: 1,
            retryDelay: 2000,
        })
            .then(r => r.json())
            .then(j => console.log(j))
            .then(() => resetState())
            .catch(reportError);
    };

    const renderGrant = (row, idx) => {
        const active = row.active;
        const type = row.type === 'grant-bot' ? 'Botmaker platform' : 'BigQuery data';

        return <TableRow key={'t_' + idx} style={{opacity: `${active ? 100 : 70}%`}}>
            <TableCell>{row.name}</TableCell>
            <TableCell>{row.business_id}</TableCell>
            <TableCell>{type + ' - ' + Object.entries(RESTRICTION_LEVEL).filter(p => '' + p[1] === row.restriction).map(p => p[0].replaceAll('_', ' '))}</TableCell>
            <TableCell>{row.exp ? DateTime.fromMillis(row.exp).toLocaleString(DateTime.DATETIME_FULL) : '--'}</TableCell>
            <TableCell align="center">
                {active ?
                    <IconButton aria-label="delete" onClick={() => onDeleteGrant(row.email, row.business_id, row.type)}>
                        <DeleteIcon/>
                    </IconButton>
                    : 'expired'
                }
            </TableCell>
        </TableRow>;
    };


    return <div className='root'>
        {requesting && <LinearProgress/>}

        <span className='title'>Here botmakers request access a client's bot. This request will be saved and audited based on our security policies.</span>

        <div className='section'>
            <ProjectSearch
                setRequesting={setRequesting}
                onBusiness={setBusiness}
                business={business}
            />

            <ButtonGroup className='formItem' size="small" color="primary">
                {Object.entries(RESTRICTION_LEVEL).filter(v => v[1] !== 2).map((itm, idx) => <Tooltip key={'br_' + idx} placement='top' title={help[itm[0]] || ''}>
                    <Button disabled={requesting} onClick={() => setRestriction(itm[1])} variant={restriction === itm[1] ? 'outlined' : 'contained'}>
                        {itm[0].replaceAll('_', ' ')}
                    </Button>
                </Tooltip>)}
            </ButtonGroup>

            <ButtonGroup className='formItem' size="small" color="primary">
                {Object.entries({EXPIRES_IN_24_HS: '24h', NEVER_EXPIRES: 'never'}).map((itm, idx) => <Tooltip key={'br2_' + idx} placement='top' title={help[itm[0]] || ''}>
                    <Button disabled={requesting} onClick={() => setExpire(itm[1])} variant={expire === itm[1] ? 'outlined' : 'contained'}>
                        {itm[0].replaceAll('_', ' ')}
                    </Button>
                </Tooltip>)}
            </ButtonGroup>
        </div>

        <div className='section'>
            <TextField
                id="reason"
                label="Request reason (explain why access is needed or link a Trello card URL)"
                multiline
                fullWidth
                value={reason}
                onChange={evt => setReason(evt.target.value)}
                rows={3}
                required
                size='small'
                variant="outlined"
            />
        </div>

        <div className='section' style={{justifyContent: 'center'}}>
            <Button disabled={requesting || !business || reason.trim().length < 5} onClick={() => accessReady ? gotoAccess() : requestAccess()} variant='contained' color='primary'
                    style={{minWidth: '60%'}}>{requesting ? 'Requesting' : (accessReady ? 'go botmaker' : 'Request access')}</Button>
        </div>

        <div className='section-table'>
            {grants.length > 0 && <Paper>
                <Typography variant="h6">Recent grants:</Typography>
                <TableContainer style={{height: '300px'}}>
                    <Table size='small'>
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>Project</TableCell>
                                <TableCell>Type</TableCell>
                                <TableCell>Expires at</TableCell>
                                <TableCell align="center">Cancel grant</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {grants.map(renderGrant)}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>}
        </div>
    </div>;
};
export default PlatformGrant;
