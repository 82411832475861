import {getFirebaseAuth} from '../Auth';

require('es6-promise').polyfill();

const originalFetch = require('isomorphic-fetch');
export const fetch = require('fetch-retry')(originalFetch);

export const RESTRICTION_LEVEL = {
    RESTRICTED: 3,
    RESTRICTED_BUT_CONTACT_ID: 2,
    NO_RESTRICTION: 1
};

export const fetchAuth = async (endpoint, init) => {
    const url = (window.location.host.includes('localhost') ? 'http://localhost:9000' : '') + endpoint;
    const idToken = await getFirebaseAuth().currentUser.getIdToken();
    const opts = {...init, headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${idToken}`}};

    return fetch(url, opts);
};

const getWidth = () => window.innerWidth
    || document.documentElement.clientWidth
    || document.body.clientWidth;

export const isMobile = () => getWidth() < 850;
