import React, {useState} from 'react';
import {Route, Switch, useHistory, useLocation} from "react-router-dom";
import Access from './components/Access';
import Menu from './components/Menu';
import AvatarUser from './components/AvatarUser';
import {useBusinesses} from "./components/botsService";

import {AppContext} from "./AppContext";

import './app.scss';

const reportErrorImpl = e => console.error(e);

const App = () => {
    const history = useHistory();
    const location = useLocation();
    const [accessed, setAccessed] = useState(false);

    const push = p => history.push({pathname: p});

    const reportError = e => reportErrorImpl(e);

    const root = 'https://storage.googleapis.com/m-infra.appspot.com/public/botmaker/online-sales/v4/';

    return <AppContext.Provider value={{push, location, reportError, root, useBusinesses}}>

        {accessed && <AvatarUser/>}

        <div className='main'>

            <img alt='Botmaker' src={`${root}logo.svgz`} width={135} className="logo"/>

            <Switch>
                {/*<Redirect exact from='/' to='/new'/>*/}

                <Route exact path="/">
                    <Access setAccessed={setAccessed}/>
                </Route>

                <Route path="/menu">
                    <Menu/>
                </Route>

            </Switch>
        </div>
    </AppContext.Provider>;
};

export default App;
