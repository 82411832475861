import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter as Router} from "react-router-dom";

import App from './App';

if (
    window.location.protocol !== 'https:' &&
    !window.location.host.includes('localhost') &&
    !window.location.host.includes('192.168.')
)
    window.location = 'https://go.botmaker.com/new/';

ReactDOM.render(
    <React.StrictMode>
        <Router>
            <App/>
        </Router>
    </React.StrictMode>,
    document.getElementById('root')
);
