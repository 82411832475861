import React, {useState} from 'react';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import {makeStyles} from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import BigQueryMenu from './BigQueryMenu';
import PlatformGrant from './PlatformGrant';
import WAAccessTokens from './WAAccessTokens';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        maxWidth: '90%',
        height: '62vw',
        backgroundColor: theme.palette.background.paper,
        margin: '0 auto',
        marginTop: '3em'
    },

    spaced: {
        margin: '1em'
    }
}));

const TabPanel = (props) => {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            style={{overflowY: 'auto', height: '86vh'}}
            id={`tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    {children}
                </Box>
            )}
        </div>
    );
}

const Menu = () => {
    // const appContext = useContext(AppContext);
    const classes = useStyles();

    const [value, setValue] = useState(0);
    // const user = getFirebaseAuth().currentUser;

    return <div className={classes.root}>
        <Paper square>
            <Tabs
                value={value}
                indicatorColor="primary"
                textColor="primary"
                onChange={(event, newValue) => setValue(newValue)}
            >
                <Tab label="Client's Bot Access"/>
                <Tab label="BigQuery Access"/>
                <Tab label="Tokens & Channels"/>
            </Tabs>
        </Paper>

        {[<PlatformGrant/>, <BigQueryMenu/>, <WAAccessTokens/>].map((comp, idx) => <TabPanel value={value} key={'p_' + idx} index={idx}>{comp}</TabPanel>)}

    </div>;
};
export default Menu;
