import React, {useContext, useEffect, useState} from 'react';
import {AppContext} from "../AppContext";
import {fetchAuth} from './Utils';
import LinearProgress from '@material-ui/core/LinearProgress';
import ProjectSearch from './ProjectSearch';
import IconButton from '@material-ui/core/IconButton';
import FileCopy from '@material-ui/icons/FileCopy';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const WAAccessTokens = () => {
    const appContext = useContext(AppContext);
    const {reportError} = appContext;

    const [requesting, setRequesting] = useState(true);
    const [tokens, setTokens] = useState([]);
    const [business, setBusiness] = useState(null);


    useEffect(() => {
        if (business) {
            setRequesting(true);

            fetchAuth('/rest/watokens/' + business.id, {
                method: 'GET',
                retries: 3,
                retryDelay: 2000,
            })
                .then(sr => sr.json())
                .then(bs => setTokens(bs.result))
                .then(() => setRequesting(false))
                .catch(reportError);
        }
    }, [business, reportError]);


    const copyToClipboard = async (text) => navigator.clipboard.writeText(text);


    return <div className='root'>
        {requesting && <LinearProgress/>}

        <span className='title'>This forms allows botmakers to quickly lookup for WhatsApp tokens and channel useful information.</span>

        <div className='section'>
            <ProjectSearch
                requesting={requesting}
                setRequesting={setRequesting}
                onBusiness={setBusiness}
            />
        </div>

        {tokens.length > 0 &&
        <TableContainer className='section' component={Paper}>
            <Table aria-label="channels" size='small'>
                <TableHead>
                    <TableRow>
                        <TableCell>Number</TableCell>
                        <TableCell>Waba</TableCell>
                        <TableCell>Id</TableCell>
                        <TableCell>Endpoint</TableCell>
                        <TableCell>Access Token</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {tokens.map((itm, idx) => (
                        <TableRow key={'t_' + idx}>
                            <TableCell component="th" scope="row">{itm.WHATSAPP_NUMBER} </TableCell>
                            <TableCell>{itm.WABA_ID} </TableCell>
                            <TableCell>{itm.id} </TableCell>
                            <TableCell>{itm.WHATSAPP_ENDPOINT} </TableCell>
                            <TableCell>
                                <IconButton aria-label="copy" onClick={() => copyToClipboard(itm.WHATSAPP_ENDPOINT_AUTH_TOKEN)}>
                                    <FileCopy/>
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
        }
    </div>;
};
export default WAAccessTokens;
