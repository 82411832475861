import React, {useContext, useEffect} from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {AppContext} from "../AppContext";

const ProjectSearch = props => {
    const {business, onBusiness, setRequesting} = props;
    const appContext = useContext(AppContext);
    const {useBusinesses} = appContext;

    const {businesses} = useBusinesses();

    useEffect(() => {
        if (businesses && businesses.length)
            setRequesting(false);
    }, [businesses, setRequesting]);

    return <Autocomplete
        size='small'
        id="businesses"
        options={businesses}
        selectOnFocus
        openOnFocus
        clearOnBlur
        autoSelect
        autoComplete
        autoHighlight
        autoFocus
        getOptionLabel={option => option.dn + ' - ' + option.id}
        value={business}
        onChange={(_evt, v) => onBusiness(v)}
        style={{width: 400}}
        renderInput={(params) => <TextField {...params} autoFocus label="Bot name" variant="outlined"/>}
    />;
};
export default ProjectSearch;
